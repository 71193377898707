var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","max-width":"550"}},[_c('v-list',[_vm._l((_vm.layoutPlugins),function(layoutPlugin){return [(layoutPlugin.plugin)?_c('v-list-item',{key:layoutPlugin.id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(layoutPlugin.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(layoutPlugin.plugin.name)+" ")])],1),_c('v-list-item-action',{staticClass:"flex-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.previewComponent('plugin', layoutPlugin.id)}}},on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Preview Plugin ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.configureLayoutPluginId = layoutPlugin.id}}},on),[_c('v-icon',[_vm._v(" mdi-wrench ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Configure Plugin Options ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.removingPlugin},on:{"click":function($event){return _vm.removePlugin(layoutPlugin.id)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Remove Plugin From Layout ")])])],1)],1):_c('v-list-item',{key:layoutPlugin.id,attrs:{"color":"error"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(layoutPlugin.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"error--text"},[_vm._v(" This plugin has been deprecated! Please delete it. ")])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.removingPlugin},on:{"click":function($event){return _vm.removePlugin(layoutPlugin.id)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Remove Plugin From Layout ")])])],1)],1)]})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.addPlugin = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add Plugin ")],1)],1),_c('m-component-adder',{attrs:{"layout-id":_vm.layout.id,"component-type":"plugin"},on:{"created":function($event){_vm.configureLayoutPluginId = $event}},model:{value:(_vm.addPlugin),callback:function ($$v) {_vm.addPlugin=$$v},expression:"addPlugin"}}),_c('m-component-configurator',{attrs:{"layout-item-id":_vm.configureLayoutPluginId,"component-type":"plugin"},on:{"done":function($event){_vm.configureLayoutPluginId = ''}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }