<template>
  <v-card flat max-width="550">
    <v-list>
      <template
        v-for="layoutPlugin in layoutPlugins"
      >
        <v-list-item
          v-if="layoutPlugin.plugin"
          :key="layoutPlugin.id"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ layoutPlugin.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ layoutPlugin.plugin.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="flex-row">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="previewComponent('plugin', layoutPlugin.id)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Preview Plugin
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="configureLayoutPluginId = layoutPlugin.id"
                >
                  <v-icon>
                    mdi-wrench
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Configure Plugin Options
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  :loading="removingPlugin"
                  v-on="on"
                  @click="removePlugin(layoutPlugin.id)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Remove Plugin From Layout
              </span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-else
          :key="layoutPlugin.id"
          color="error"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ layoutPlugin.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="error--text">
              This plugin has been deprecated! Please delete it.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  :loading="removingPlugin"
                  v-on="on"
                  @click="removePlugin(layoutPlugin.id)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Remove Plugin From Layout
              </span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        outlined
        color="primary"
        @click="addPlugin = true"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Add Plugin
      </v-btn>
    </v-card-actions>
    <m-component-adder
      v-model="addPlugin"
      :layout-id="layout.id"
      component-type="plugin"
      @created="configureLayoutPluginId = $event"
    />
    <m-component-configurator
      :layout-item-id="configureLayoutPluginId"
      component-type="plugin"
      @done="configureLayoutPluginId = ''"
    />
  </v-card>
</template>

<script>
import MComponentConfigurator from '@backend/components/MComponentConfigurator'
import MComponentAdder from '@backend/components/MComponentAdder'
export default {
  components: {
    MComponentConfigurator,
    MComponentAdder
  },
  data: () => ({
    addPlugin: false,
    removingPlugin: false,
    configureLayoutPluginId: null
  }),
  computed: {
    layout() {
      return this.$store.getters.layoutById(this.$route.params.id)
    },
    layoutPlugins() {
      return this.layout ? this.layout.plugins || [] : []
    }
  },
  methods: {
    async removePlugin(layoutPluginId) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.removingPlugin = true
        try {
          await this.$db.collection('layout-plugins').doc(layoutPluginId).delete()
        } catch (e) {
          alert(JSON.stringify(e))
        }
        this.removingPlugin = null
      }
    }
  }
}
</script>
